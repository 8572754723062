import { CapsizedText as Text } from '@/components/text';
import { useDebugStore } from '@/hooks/use-debug-store';
import { styled } from '@/stitches.config';
import { blackA } from '@radix-ui/colors';
import { GearIcon, MoonIcon, SunIcon } from '@radix-ui/react-icons';
import { Root as BaseToggle } from '@radix-ui/react-toggle';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';
import { useTheme } from 'next-themes';
import { useCallback, useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

export function DialogThemeSwitch() {
  const { setTheme, resolvedTheme } = useTheme();
  const [mounted, setMounted] = useState(false);

  const {
    isDebugActive,
    isGridVisible,
    toggleDebug,
    toggleGrid,
    isLevaVisible,
    toggleLeva,
  } = useDebugStore(
    useCallback(
      (state) => ({
        isDebugActive: state.debug,
        isGridVisible: state.isGridVisible,
        toggleDebug: state.toggleDebug,
        toggleGrid: state.toggleGrid,
        isLevaVisible: state.isLevaVisible,
        toggleLeva: state.toggleLeva,
      }),
      []
    ),
    shallow
  );

  /**
   * This prevents hydration warnings
   */
  useEffect(() => setMounted(true), []);

  if (!mounted) {
    return null;
  }

  const handleToggleGroup = (value: string) => {
    if (value) {
      setTheme(value);
    }
  };

  return (
    <>
      {/* display mode */}
      <Text
        size="1"
        css={{
          display: 'block',
          marginBottom: '1em',
        }}
      >
        Change display mode
      </Text>
      <ToggleGroup
        type="single"
        defaultValue={resolvedTheme}
        aria-label="Display mode"
        onValueChange={handleToggleGroup}
        css={{
          marginBottom: '$6',
        }}
      >
        <ToggleGroupItem value="light" aria-label="Light mode">
          <SunIcon />
        </ToggleGroupItem>
        <ToggleGroupItem value="system" aria-label="System preference">
          <GearIcon />
        </ToggleGroupItem>
        <ToggleGroupItem value="dark" aria-label="Dark mode">
          <MoonIcon />
        </ToggleGroupItem>
      </ToggleGroup>

      {/* debug mode */}

      {/* {process.env.NODE_ENV === 'development' && ( */}
      <>
        <Text
          size="1"
          css={{
            display: 'block',
            marginBottom: '1em',
          }}
        >
          Toggle debug mode
        </Text>

        <Toggle
          aria-label="Toggle debugging"
          pressed={isDebugActive}
          onPressedChange={toggleDebug}
          css={{
            borderRadius: 4,
            marginBottom: '1em',
          }}
        >
          {isDebugActive ? 'On' : 'Off'}
        </Toggle>
      </>
      {/* )} */}

      {/* Grid debug mode */}
      <Text
        size="1"
        css={{
          display: 'block',
          marginBottom: '1em',
        }}
      >
        Toggle the grid
      </Text>

      <Toggle
        aria-label="Toggle the grid"
        pressed={isGridVisible}
        onPressedChange={() => toggleGrid()}
        css={{
          borderRadius: 4,
          marginBottom: '1em',
        }}
      >
        {isGridVisible ? 'On' : 'Off'}
      </Toggle>

      {/* leva debug mode */}
      <Text
        size="1"
        css={{
          display: 'block',
          marginBottom: '1em',
        }}
      >
        Toggle the controls
      </Text>

      <Toggle
        aria-label="Toggle controls"
        pressed={isLevaVisible}
        onPressedChange={() => toggleLeva()}
        css={{
          borderRadius: 4,
        }}
      >
        {isLevaVisible ? 'On' : 'Off'}
      </Toggle>
    </>
  );
}

const Toggle = styled(BaseToggle, {
  all: 'unset',
  backgroundColor: '$gray2',
  color: '$gray11',
  height: 35,
  minWidth: 35,
  padding: '0 $3',
  borderRadius: 4,
  display: 'flex',
  fontSize: 15,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 1px 10px ${blackA.blackA7}`,

  '&:hover': {
    backgroundColor: '$gray3',
  },

  '&[data-state=on]': {
    backgroundColor: '$gray6',
    color: '$gray12',
  },

  '&:focus': {
    boxShadow: `0 0 0 1px $colors$gray7`,
  },
});

const StyledItem = styled(ToggleGroupPrimitive.Item, {
  all: 'unset',
  backgroundColor: '$gray2',
  color: '$gray11',
  height: 35,
  minWidth: 35,
  display: 'flex',
  fontSize: 15,
  lineHeight: 1,
  alignItems: 'center',
  justifyContent: 'center',
  marginLeft: 1,
  padding: '0 $2',

  '&:first-child': {
    marginLeft: 0,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
  },

  '&:last-child': {
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
  },

  '&:hover': {
    backgroundColor: '$gray6',
  },

  '&[data-state=on]': {
    backgroundColor: '$gray5',
    color: '$gray11',
  },

  '&:focus': {
    position: 'relative',
    outline: `0 0 0 1px $colors$gray7`,
  },
});

const StyledToggleGroup = styled(ToggleGroupPrimitive.Root, {
  display: 'inline-flex',
  backgroundColor: '$gray6',
  borderRadius: 4,
  boxShadow: `0 2px 10px ${blackA.blackA7}`,
});

// Exports
const ToggleGroup = StyledToggleGroup;
const ToggleGroupItem = StyledItem;
