import { styled } from '@/stitches.config';

export const LogoWrapper = styled('div', {
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$6',
  width: '100%',
  zIndex: 1,
  '@bp3': {
    padding: 0,
    width: 'auto',
  },
});
