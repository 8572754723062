import { styled } from '@/stitches.config';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect } from 'react';
import { NavigationContext } from './context';
import { NavLink } from './nav-link';
import { useNavigationStore } from '@/hooks/use-navigation-store';

const menuItems = [
  {
    label: 'Services',
    href: '/services',
  },
  {
    label: 'Work',
    href: '/work',
    hasChildRoute: true,
  },
  {
    label: 'About',
    href: '/about',
  },
  {
    label: 'Research',
    href: '/research',
  },
  {
    label: 'Careers',
    href: '/careers',
  },
  {
    label: 'Contact',
    href: '/contact',
  },
];

type ModalNavigationProps = {
  invert?: boolean;
};
export function ModalNavigation({ invert = false }: ModalNavigationProps) {
  const { route, asPath } = useRouter();

  return (
    <OrderedList>
      {menuItems.map((item) => (
        <NavItem
          key={`mobile-nav-menu-${item.href}`}
          label={item.label}
          href={item.href}
          invert={invert}
          isActive={
            item.hasChildRoute
              ? route.includes(item.href)
              : asPath === item.href
          }
        />
      ))}
    </OrderedList>
  );
}

type NavigationProps = {
  invert?: boolean;
  onComplete?: (a: boolean) => void;
};

export function Navigation({ invert = false, onComplete }: NavigationProps) {
  const { route, asPath } = useRouter();

  const { hasAnimated, toggleHasAnimated } = useNavigationStore(
    useCallback(
      (state) => ({
        hasAnimated: state.hasAnimated,
        toggleHasAnimated: state.toggleHasAnimated,
      }),
      []
    )
  );

  useEffect(() => {
    if (!hasAnimated) {
      toggleHasAnimated();
    }
  }, [hasAnimated, toggleHasAnimated]);

  const { delay, staggerDelay, duration } = useContext(NavigationContext);
  const animateDirection = 'top';

  return (
    <OrderedList>
      {menuItems.map((item, index) => (
        <motion.li
          key={`menu-item-${item.href}`}
          initial={hasAnimated ? false : 'from'}
          animate={'to'}
          variants={{
            from: { y: animateDirection ? '-100%' : '100%', opacity: 0 },
            to: {
              opacity: 1,
              y: 0,
              transition: {
                delay: delay + index * staggerDelay,
                duration: duration,
              },
            },
          }}
          style={{
            overflow: 'hidden',
            listStyleType: 'none',
            paddingBottom: '2px', // Height of the bottom marker
            pointerEvents: 'auto',
          }}
          onAnimationComplete={() => {
            if (index === menuItems.length - 1 && onComplete) {
              onComplete(true);
            }
          }}
        >
          <NavItem
            label={item.label}
            href={item.href}
            invert={invert}
            isActive={
              item.hasChildRoute
                ? route.includes(item.href)
                : asPath === item.href
            }
          />
        </motion.li>
      ))}
    </OrderedList>
  );
}

const OrderedList = styled('ol', {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflow: 'hidden',
  gap: '$1',
  marginLeft: '-$2',

  '@bp1': {
    gap: 0,
  },

  '@bp2': {
    flexDirection: 'row',
    gap: '$2',
  },

  '@bp3': {
    gap: '$4',
    marginRight: '-$3', // realign nav items to the edge of the grid
  },
});

interface NavItemProps {
  href: string;
  label: string;
  isActive?: boolean;
  invert?: boolean;
}

function NavItem({ href, isActive, label, invert }: NavItemProps) {
  return (
    <NavLink href={href} isActive={isActive} invert={invert}>
      {label}
    </NavLink>
  );
}
