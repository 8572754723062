import { Box } from '@/components/box';
import { DialogThemeSwitch } from '@/components/dialog/dialog-theme-switch';
import { styled } from '@/stitches.config';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { MagnifyingGlassIcon } from '@radix-ui/react-icons';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { isPresent } from 'ts-is-present';
import { Projects, getProjects } from '@/lib/sanity/queries/get-projects';

const fetcher = () => {
  return getProjects(false);
};

export function Content() {
  const inputRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useState('');

  let drilldown = [];

  let mode = 'general';
  drilldown = ['home'];

  if (input.startsWith('>')) {
    mode = 'command';
    drilldown = ['home', 'command'];
  } else if (input.length > 2) {
    mode = 'search';
    drilldown = ['home', 'search a project'];
  }

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current.focus();
    }
  }, []);

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current.focus();
    }
  }, []);

  const { data } = useSWR('allProjects', fetcher);

  let searchResults: Projects = [];
  if (mode === 'search' && isPresent(data)) {
    searchResults = data.filter((project) => {
      const { slug, subtitle } = project;
      if (slug.includes(input) || subtitle.includes(input)) {
        return true;
      }
      return false;
    });
  }

  return (
    <div>
      <DialogTitle>Command menu</DialogTitle>

      <Box
        css={{
          display: 'flex',
          gap: '$1',
          margin: '$3 auto',
          fontSize: '12px',
          textTransform: 'capitalize',
          lineHeight: '1',
        }}
      >
        {drilldown.map((tag) => {
          return <Tag key={tag}>{tag}</Tag>;
        })}
      </Box>
      <Box
        css={{
          position: 'relative',
          paddingLeft: 25,
          marginBottom: '$5',
        }}
      >
        <SearchIcon width={20} height={20} />
        <Input
          ref={inputRef}
          autoComplete="off"
          type="text"
          onChange={(e) => setInput(e.target.value)}
          placeholder="What are you searching for?"
        />
      </Box>
      <Box>
        {mode === 'search' && (
          <>
            {searchResults.map((result) => {
              return (
                <Link
                  key={result._id}
                  passHref
                  href={`/project/${result.slug}`}
                >
                  <Box
                    as="a"
                    css={{
                      display: 'block',
                      fontSize: 14,
                      color: '$gray11',
                      background: '$gray4',
                      padding: '$3 $4',
                      textDecoration: 'none',
                    }}
                  >
                    <Box
                      css={{
                        color: '$gray12',
                      }}
                    >
                      {result.title}
                    </Box>
                    <Box>{result.subtitle}</Box>
                  </Box>
                </Link>
              );
            })}
          </>
        )}

        {mode === 'command' && <DialogThemeSwitch />}
      </Box>
    </div>
  );
}

const Tag = styled('div', {
  padding: '$2',
  background: '$gray3',
  color: '$gray11',
  border: '1px solid $gray6',
  borderRadius: 3,
});

const StyledTitle = styled(DialogPrimitive.Title, {
  margin: 0,
  fontWeight: 500,
  color: '$gray12',
  fontSize: '$3',
});

const DialogTitle = StyledTitle;

const Input = styled('input', {
  width: '100%',
  fontSize: '$2',
  padding: '$2 $3',
  border: '1px solid $gray5',
  borderRadius: 3,
  '&:focus-visible': {
    outline: '1px solid $gray8',
  },
});

const SearchIcon = styled(MagnifyingGlassIcon, {
  position: 'absolute',
  left: 0,
  top: 8,
});
