import create from 'zustand';

interface DebugState {
  isGridVisible: boolean;
  toggleGrid: () => void;
  debug: boolean;
  toggleDebug: () => void;
  isLevaVisible: boolean;
  toggleLeva: () => void;
}

export const useDebugStore = create<DebugState>((set) => ({
  isGridVisible: false,
  toggleGrid: () => {
    set((state: { isGridVisible: boolean }) => ({
      isGridVisible: !state.isGridVisible,
    }));
  },
  debug: false,
  toggleDebug: () =>
    set((state: { debug: boolean }) => ({ debug: !state.debug })),
  isLevaVisible: false,
  toggleLeva: () =>
    set((state: { isLevaVisible: boolean }) => ({
      isLevaVisible: !state.isLevaVisible,
    })),
}));
