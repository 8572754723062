import { ZodSchema } from 'zod';
import { getClient } from './sanity.server';

/**
 * Helper function that invokes the passed groq query and parses the returned data
 */
export const getData = async <T>(
  query: string,
  schema: ZodSchema<T>,
  preview: boolean,
  params?: Parameters<ReturnType<typeof getClient>['fetch']>[1]
) => {
  const client = getClient(preview);

  const response = await (async () => {
    if (params) {
      return client.fetch(query, params);
    }
    return client.fetch(query);
  })();

  const data = schema.parse(response);
  return data;
};
