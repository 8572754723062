import { useDebugStore } from '@/hooks/use-debug-store';
import dynamic from 'next/dynamic';
import React from 'react';
import { defaultDelay, defaultDuration, defaultStaggerDelay } from './config';

export const NavigationContext = React.createContext({
  delay: defaultDelay,
  staggerDelay: defaultStaggerDelay,
  duration: defaultDuration,
});

interface ChildrenProps {
  children: React.ReactNode;
}

const LevaProvider = dynamic<ChildrenProps>(
  () =>
    import('@/components/navigation/with-leva').then(
      (mod) => mod.NavigationLevaProvider
    ),
  { ssr: true }
);

const DefaultProvider = dynamic<ChildrenProps>(
  () =>
    import('@/components/navigation/without-leva').then(
      (mod) => mod.DefaultNavigationProvider
    ),
  { ssr: true }
);

export function NavigationProvider({ children }: ChildrenProps) {
  const { isLevaVisible } = useDebugStore();
  const Provider = isLevaVisible ? LevaProvider : DefaultProvider;
  return <Provider>{children}</Provider>;
}
