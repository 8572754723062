import { Box } from '@/components/box';
import { styled } from '@/stitches.config';
import { blackA } from '@radix-ui/colors';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { Close } from '@/components/icons';
import { keyframes } from '@stitches/react';
import React, { useEffect, useState } from 'react';
import useKeyboardJs from 'react-use/lib/useKeyboardJs';
import { Content } from './content';

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'scale(.96)' },
  '100%': { opacity: 1, transform: 'scale(1)' },
});

export function CommandMenu() {
  const [isOpen, setIsOpen] = useState(false);

  const [isPressed] = useKeyboardJs([
    'shift + k',
    'command + k',
    'windows + k',
    'win + k',
    'super + k',
  ]);

  useEffect(() => {
    if (isPressed) {
      if (isOpen) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    }

    /**
     * Leave me alone. I know what I'm doing! - Kimi
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPressed]);

  function handleOpenChange(callback: boolean) {
    setIsOpen(callback);
  }

  return isOpen ? (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Box
        css={{
          position: 'fixed',
          zIndex: 1,
          inset: 0,
          display: 'flex',
          placeItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DialogContent>
          <Content />

          <DialogClose asChild>
            <IconButton>
              <Close />
            </IconButton>
          </DialogClose>
        </DialogContent>
      </Box>
    </Dialog>
  ) : null;
}

interface DialogT extends DialogPrimitive.DialogProps {
  children?: React.ReactNode;
}

function Dialog({ children, ...props }: DialogT) {
  return (
    <DialogPrimitive.Root {...props}>
      <StyledOverlay />
      {children}
    </DialogPrimitive.Root>
  );
}

const StyledContent = styled(DialogPrimitive.Content, {
  position: 'relative',
  backgroundColor: '$gray2',
  borderRadius: 3,
  boxShadow:
    'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  width: '90vw',
  maxWidth: '450px',
  maxHeight: '85vh',
  padding: 25,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

// Exports
const DialogContent = StyledContent;
const DialogClose = DialogPrimitive.Close;

const StyledOverlay = styled(DialogPrimitive.Overlay, {
  backgroundColor: blackA.blackA11,
  position: 'fixed',
  zIndex: 1,
  inset: 0,

  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
});

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '$gray9',
  position: 'absolute',
  top: 10,
  right: 10,
  '&:focus': { background: '$gray3', outline: '1px solid $gray7' },
  '&:hover': { background: '$gray5' },
  '&:active': {
    background: '$gray6',
    color: '$gray10',
  },
});
